import Vue from 'vue'

function getDicList(flag) {
	return new Promise(async function (resolve, reject) {
		const {
			data: res
		} = await Vue.prototype.$http.get("/openapi/web_proxy_get", {
			params: {
				path: "ashx/codeList.ashx",
				flag
			}
		});
		if (res.code !== 200) {
			return reject("");
		}
		resolve(res.data);
	});
}

function getUserId() {
	return window.sessionStorage.getItem("userId");
}

function imageResize(url, width){
	return `${url}?x-oss-process=image/resize,w_${width}/format,jpg`
}

export {
	getDicList, getUserId, imageResize
}