<template>
<div>
  <el-table :data="deliverProgressList" border stripe>
    <el-table-column width="150px" label="配送凭证">
      <template slot-scope="scope">
        <div style="position:relative;width: 150px;height: 80px;">
          <el-image v-for="(item, index) in scope.row.images" :key="index"
            :style="{ 'width': '80px', 'height': '80px', 'position': 'absolute', 'z-index': 10, 'margin-left': (index * 20) + 'px', 'border': '1px solid #999' }"
            :src="$imageResize(item, 100)" :preview-src-list="scope.row.images">
          </el-image>
        </div>
      </template>
    </el-table-column>
    <el-table-column width="120px" label="配送时间" prop="deliverDate">
      <template slot-scope="scope">
        {{ scope.row.deliverDate ? new Date(scope.row.deliverDate).Format("yyyy年MM月dd日") : "--" }}
      </template>
    </el-table-column>
    <el-table-column width="80px" label="配送数量">
      <template slot-scope="scope">
        <el-tag type="success">{{ scope.row.number }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column width="100px" label="配送人">
      <template slot-scope="scope">
        {{ scope.row.deliverName || "无" }}
      </template>
    </el-table-column>
    <el-table-column width="120px" label="配送人联系方式">
      <template slot-scope="scope">
        {{ scope.row.mobile || "无" }}
      </template>
    </el-table-column>
    <el-table-column width="100px" label="签收人">
      <template slot-scope="scope">
        {{ scope.row.signerName || "无" }}
      </template>
    </el-table-column>
    <el-table-column width="120px" label="签收人联系方式">
      <template slot-scope="scope">
        {{ scope.row.signerMobile || "无" }}
      </template>
    </el-table-column>
    <el-table-column width="200px" label="更新时间" prop="createtime">
      <template slot-scope="scope">
        {{ scope.row.createtime ? new Date(scope.row.createtime).Format("yyyy年MM月dd日 HH:mm:ss") : "--" }}
      </template>
    </el-table-column>
    <el-table-column label="备注">
      <template slot-scope="scope"> </template>
    </el-table-column>
    <!-- <el-table-column label="操作" fixed="right" width="200px">
      <template slot-scope="scope">
        <el-button type="primary" icon="el-icon-edit" size="mini" @click="editInfo(scope.row)">编辑</el-button>
        <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeById(scope.row.id)">删除</el-button>
      </template>
    </el-table-column> -->
  </el-table>

  <el-dialog title="更新配送进度" :visible.sync="updateProgressDialogVisible" width="40%" :close-on-press-escape="false"
    :close-on-click-modal="false"  append-to-body modal-append-to-body>
    <AddDeliver :detail="currentDeliverInfo" :isEdit="true" :orderNo="orderNo" @refreshList="refreshList"></AddDeliver>
  </el-dialog>
</div>
</template>

<script>
import _ from "lodash";
import upload from "@/components/upload";
import AddDeliver from "./AddDeliver.vue";
export default {
  components: {
    upload,
    AddDeliver
  },
  props: {
    orderNo: String
  },
  data() {
    return {
      deliverProgressList: [],
      currentDeliverInfo: "",
      isEditDeliver: true,
      updateProgressDialogVisible: false
    };
  },
  computed: {

  },
  watch: {
    orderNo: {
      immediate: true,
      handler(nval) {
        if (nval) {
          // this.getDeliverList();
        }
      },
    },
  },
  created() {
    let that = this;
  },
  mounted() {

  },
  methods: {
    refreshList(){
      //  this.updateProgressDialogVisible = false;
       this.getDeliverList();
      //  this.$emit("refreshList");
    },
    async getDeliverList() {
      this.deliverProgressList = [];
      const { data: res } = await this.$http.get("/web_api/shop/deliver_list", {
        params: {
          orderNo: this.orderNo,
        },
      });
      if (res.code !== 200) {
        return this.$message.error("获取订单服务列表失败！");
      }
      res.data.forEach((element) => {
        element.images = element.image.split(",");
        this.deliverProgressList.push(element);
      });
    },
    editInfo(row) {
      this.currentDeliverInfo = row;
      this.updateProgressDialogVisible = true;
    },
    removeById(ID) {
      this.$confirm('此操作将永久删除该配送记录, 是否继续?', '温馨提示', {
				confirmButtonText: '确定',
				callback: async (action) => {
					if (action == 'confirm') {
						const {
							data: res
						} = await this.$http.post("web_api/supplier/delete_deliver", {
							id: ID, orderNo: this.orderNo
						});
						if (res.code !== 200) {
							return this.$message.error("操作失败！");
						}
						this.$message.success("操作成功！");
						this.getDeliverList();
            this.$emit("refreshList")
					}
				}
			});
    }
  },
};
</script>

<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
</style>
