<template>
  <div class="block">
    <el-timeline>
      <el-timeline-item
        size="large"
        :color="activeColor"
        :timestamp="item.procssDatetime"
        v-for="(item, index) in progressList"
        :key="index"
        placement="top"
      >
        <el-card>
          <h4><i class="el-icon-data-board"></i> {{ getServiceType(item.type) }} <el-tag type="success" v-if="item.ServiceProcssStatusName" style="margin-left: 15px">{{  item.ServiceProcssStatusName }}</el-tag> <el-tag type="danger" v-if="!item.ServiceProcssStatusName" style="margin-left: 15px"> 已取消</el-tag> </h4>
          <p v-if="item.driver">
             <el-button icon="el-icon-truck" size="small" type="primary">{{item.driver.name}}({{item.driver.mobile}})</el-button>
          </p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { processList } from "@/config/config.js";
export default {
  name: "Dashboard",
  props: {
    orderNo: {
      type: String,
      default: "",
    }, // 订单对象
  },
  components: {},
  data() {
    return {
      orderCountDate: "",
      loading: false,
      dataEmpty: false,
      activeColor: "#FFBD32",
      progressList: [],
      serviceTypeList: [],
      serviceVarietyList: [],
    };
  },
  created() {
    this.getDictionaryList();
  },
  watch: {
    orderNo: {
      immediate: true,
      handler(nval) {
        if (nval) {
          this.getProgressList();
        }
      },
    },
  },
  methods: {
    async getDictionaryList() {
      this.$getDicList(1).then((list) => {
        this.serviceTypeList = list;
      });
      this.$getDicList(2).then((list) => {
        this.serviceVarietyList = list;
      });
      console.log("获取服务");
    },
    getServiceType(type) {
      if (this.serviceTypeList.length) {
        let item = this.serviceTypeList.find((item) => item.value == type);
        return item ? item.name : "";
      } else {
        return "";
      }
    },
    getServiceVariety(variety) {
      if (this.serviceVarietyList.length) {
        let item = this.serviceVarietyList.find(
          (item) => item.value == variety
        );
        return item ? item.name : "";
      } else {
        return "";
      }
    },
    handleDateChange() {},
    async getProgressList() {
      const { data: res } = await this.$http.get("/openapi/web_proxy_get", {
        params: {
          action: "OrderProgressList",
          path: "ashxWeb/Servicesorder.ashx",
          orderId: this.orderNo,
        },
      });
      if (res.code !== 200) {
        return this.$message.error("获取服务进度失败！");
      }
      console.log(res.data);
      if (res.data) {
        this.progressList = res.data.map((item)=>{
          item.driver = item.driver && typeof item.driver == "string" ?JSON.parse(item.driver) : null;
          return item;
        });
      } else {
        // this.progressList = processList;
      }
    },
  },
};
</script>
