const menus = [{
	"id": 100,
	"authName": "仪表盘",
	"path": "home",
	"children": [],
	"order": null
},
{
	"id": 1,
	"authName": "农机服务管理",
	"path": null,
	"children": [{
		"id": 11,
		"authName": "基础农机服务列表",
		"path": "services",
		"children": [],
		"order": null
	},
	{
		"id": 12,
		"authName": "农机列表",
		"path": "machines",
		"children": [],
		"order": null
	},
	{
		"id": 13,
		"authName": "农机手列表",
		"path": "drivers",
		"children": [],
		"order": null
	},
	{
		"id": 14,
		"authName": "农机服务订单列表",
		"path": "orders_service",
		"children": [],
		"order": null
	}
	],
	"order": null
},
{
	"id": 2,
	"authName": "农资商城管理",
	"path": "goods",
	"children": [{
		"id": 21,
		"authName": "农资商品列表",
		"path": "goods",
		"children": [],
		"order": 1
	},
	{
		"id": 22,
		"authName": "农资商城订单列表",
		"path": "orders_shop",
		"children": [],
		"order": null
	},
	{
		"id": 23,
		"authName": "农资供货商列表",
		"path": "suppliers",
		"children": [],
		"order": null
	}
	],
	"order": 3
},
// {
// 	"id": 200,
// 	"authName": "农产品管理",
// 	"path": null,
// 	"children": [
// 	{
// 		"id": 201,
// 		"authName": "农产品列表",
// 		"path": "specialty_list",
// 		"children": [],
// 		"order": null
// 	},
// 	{
// 		"id": 202,
// 		"authName": "农场订单列表",
// 		"path": "specialty_orders",
// 		"children": [],
// 		"order": null
// 	}
// 	],
// 	"order": null
// },
{
	"id": 3,
	"authName": "农场管理",
	"path": null,
	"children": [{
		"id": 31,
		"authName": "农场列表",
		"path": "farms",
		"children": [],
		"order": null
	},
	{
		"id": 32,
		"authName": "农畜牧列表",
		"path": "products_farm",
		"children": [],
		"order": null
	},
	{
		"id": 33,
		"authName": "农场订单列表",
		"path": "orders_farm",
		"children": [],
		"order": null
	},
	{
		"id": 34,
		"authName": "餐品列表",
		"path": "farms_foods",
		"children": [],
		"order": null
	},
	{
		"id": 35,
		"authName": "餐品列单列表",
		"path": "orders_food",
		"children": [],
		"order": null
	},
	],
	"order": null
},
{
	"id": 4,
	"authName": "资讯管理",
	"path": null,
	"children": [{
		"id": 41,
		"authName": "资讯列表",
		"path": "news",
		"children": [],
		"order": null
	}],
	"order": null
},
{
	"id": 5,
	"authName": "会员管理",
	"path": null,
	"children": [{
		"id": 51,
		"authName": "会员列表",
		"path": "members",
		"children": [],
		"order": null
	}],
	"order": null
},
{
	"id": 6,
	"authName": "土地租赁管理",
	"path": null,
	"children": [{
		"id": 61,
		"authName": "土地列表",
		"path": "lands",
		"children": [],
		"order": null
	}, {
		"id": 62,
		"authName": "租赁合同列表",
		"path": "leaseRecords",
		"children": [],
		"order": null
	}],
	"order": null
},
// {
// 	"id": 6,
// 	"authName": "字典管理",
// 	"path": null,
// 	"children": [{
// 			"id": 61,
// 			"authName": "分类列表",
// 			"path": "categories",
// 			"children": [],
// 			"order": 3
// 		},
// 		// {
// 		// 	"id": 62,
// 		// 	"authName": "分类参数",
// 		// 	"path": "params",
// 		// 	"children": [],
// 		// 	"order": 2
// 		// },
// 		// {
// 		//     "id": 63,
// 		//     "authName": "字典列表",
// 		//     "path": "dictionary",
// 		//     "children": [],
// 		//     "order": null
// 		// }
// 	],
// 	"order": null
// },
{
	"id": 7,
	"authName": "数据中心",
	"path": "datas",
	"children": [{
		"id": 71,
		"authName": "交易流水",
		"path": "transactions",
		"children": [],
		"order": null
	},
	{
		"id": 72,
		"authName": "退款列表",
		"path": "refunds",
		"children": [],
		"order": null
	},
	{
		"id": 73,
		"authName": "数据报表",
		"path": "reports",
		"children": [],
		"order": null
	}
	],
	"order": 5
},
{
	"id": 8,
	"authName": "平台管理",
	"path": null,
	"children": [{
		"id": 81,
		"authName": "平台用户列表",
		"path": "admins",
		"children": [],
		"order": null
	},{
		"id": 82,
		"authName": "角色列表",
		"path": "roles",
		"children": [],
		"order": null
	}],
	"order": 1
},
// {
// 	"id": 9,
// 	"authName": "权限管理",
// 	"path": "rights",
// 	"children": [{
// 		"id": 91,
// 		"authName": "角色列表",
// 		"path": "roles",
// 		"children": [],
// 		"order": null
// 	},
// 	{
// 		"id": 92,
// 		"authName": "权限列表",
// 		"path": "rights",
// 		"children": [],
// 		"order": null
// 	}
// 	],
// 	"order": 2
// }
];

const services = [{
	id: 1,
	name: "玉米收割",
	type: "收割",
	price: "130",
	variety: "玉米",
	unit: "亩",
	image: "http://www.zgnf.net/profile/resources/2022/uploads/shhfw/demand/20231219/9495ad87df2aa57b5f7da8890e9ae3a8.jpg",
	description: "玉米收割",
	createTime: "2024-04-11 10:00:00",
	state: false
},
{
	id: 2,
	name: "油菜飞防植保",
	type: "飞防植保",
	price: "130",
	variety: "油菜",
	unit: "亩",
	image: "https://zgnf.nxagricloud.com:10008/image/resources/2022/uploads/menyuanshhfw/images/demand/20230703/5c4101630ebcff7a8b510d61dbc65e74.png",
	description: "油菜飞防植保",
	createTime: "2024-04-11 10:00:00",
	state: true
},
{
	id: 3,
	name: "麦子收割",
	type: "收割",
	price: "130",
	variety: "小麦",
	unit: "亩",
	image: "https://zgnf.nxagricloud.com:10008/image/resources/2022/uploads/menyuanshhfw/images/demand/20230629/e7d43e2cc25a12130ca79b625690c253.jpg",
	description: "麦子收割",
	createTime: "2024-04-11 10:00:00",
	state: true
}
];

const serviceTypeList = [{
	value: 1,
	label: "收割",
},
{
	value: 2,
	label: "飞防植保",
},
{
	value: 3,
	label: "收获",
}
];

const serviceVarietyList = [{
	value: 1,
	label: "玉米",
},
{
	value: 2,
	label: "油菜",
},
{
	value: 3,
	label: "小麦",
}
];


const machines = [{
	id: 1,
	name: "玉米收割机",
	type: "收割",
	variety: ["玉米"],
	image: "http://www.zgnf.net/profile/resources/2022/uploads/shhfw/demand/20231219/acfa2b5a17faabb89704bdbf04d432d5.jpg",
	description: "玉米收割机",
	from: "platform",
	createTime: "2024-04-11 10:00:00",
	brand: "长河",
	driver: null,
	gpsNo: "gps001",
	power: 1000,
	lastUploadLocation: "安徽省寿县寿春镇",
	status: 1,
	auditStatus: 1,
},
{
	id: 1,
	name: "飞防无人机",
	type: "飞防植保",
	variety: ["油菜", "小麦", "玉米"],
	image: "http://www.zgnf.net/profile/resources/2022/uploads/shhfw/images/demand/20230825/64c249ce61fd9d9c1068558ad0b2c024.png",
	description: "油菜飞防植保",
	from: "third",
	brand: "长河",
	power: 1000,
	createTime: "2024-04-11 10:00:00",
	driver: {
		id: "dri002",
		name: "李四",
		mobile: "18655457506"
	},
	gpsNo: "",
	lastUploadLocation: "",
	status: 1,
	auditStatus: 1,
},
{
	id: 1,
	name: "小麦收割机003",
	type: "收割",
	variety: ["小麦"],
	image: "http://www.zgnf.net/profile/resources/2022/uploads/shhfw/demand/20230728/96f03e145e31e24317a5af2f57ca5a78.jpg",
	description: "小麦收割机",
	from: "platform",
	brand: "长河",
	power: 1000,
	createTime: "2024-04-11 10:00:00",
	driver: null,
	gpsNo: "gps002",
	lastUploadLocation: "安徽省寿县寿春镇",
	status: -99,
	auditStatus: 0,
}
];


const drivers = [{
	id: "dri001",
	name: "张三",
	id_card: "342422199111111",
	mobile: "18655457505",
	id_card_front: "",
	id_card_backend: "",
	state: true,
	certificate: 1,
	from: "platform",
},
{
	id: "dri002",
	name: "李四",
	id_card: "342422199111111",
	mobile: "18655457505",
	id_card_front: "",
	id_card_backend: "",
	state: false,
	certificate: 0,
	from: "third"
}
]

const serviceOrderList = [{
	orderId: "SZNFFW202403301001", //订单ID
	createTime: "2024-02-28 10:22:14", //创建时间
	status: 0, //订单状态 0：'待支付定金'， 1: '待派单', 10: '待确认', 2: '服务中', 3: '待支付尾款', 99: '已完成', -99: '已取消'
	services: [{
		id: 1,
		name: "灭茬",
		type: "灭茬",
		price: "130",
		variety: ["小麦", "水稻"],
		image: "https://zgnf.nxagricloud.com:10008/image/resources/2022/uploads/menyuanshhfw/images/demand/20230629/e7d43e2cc25a12130ca79b625690c253.jpg",
		description: "灭茬",
		createTime: "2024-04-11 10:00:00",
		unit: "亩",
		machine: {
			id: "machine001", //农机ID
			name: "玉米收割机001" //农机名称
		},
		driver: {
			id: "dri001", //农机手ID
			name: "张三", //农机手名称
			mobile: "18655457505" //农机手电话
		},
		status: 3
	},
	{
		id: 2,
		name: "深翻",
		type: "深翻",
		price: "130",
		variety: ["小麦", "水稻"],
		image: "https://zgnf.nxagricloud.com:10008/image/resources/2022/uploads/menyuanshhfw/images/demand/20230629/e7d43e2cc25a12130ca79b625690c253.jpg",
		description: "深翻",
		createTime: "2024-04-11 10:00:00",
		unit: "亩",
		machine: {
			id: "machine001", //农机ID
			name: "玉米收割机001" //农机名称
		},
		driver: {
			id: "dri001", //农机手ID
			name: "张三", //农机手名称
			mobile: "18655457505" //农机手电话
		},
		status: 2
	},
	{
		id: 3,
		name: "第一遍旋耕",
		type: "第一遍旋耕",
		price: "130",
		variety: ["小麦", "水稻"],
		image: "https://zgnf.nxagricloud.com:10008/image/resources/2022/uploads/menyuanshhfw/images/demand/20230629/e7d43e2cc25a12130ca79b625690c253.jpg",
		description: "第一遍旋耕",
		createTime: "2024-04-11 10:00:00",
		unit: "亩",
		machine: {
			id: "machine001", //农机ID
			name: "玉米收割机001" //农机名称
		},
		driver: {
			id: "dri001", //农机手ID
			name: "张三", //农机手名称
			mobile: "18655457505" //农机手电话
		},
		status: 1
	}
	],
	estimateTotal: 420, //预估价格
	number: 1, //预计面积，亩
	actualArea: null, //实际测量面积，亩
	isFinalPayed: false, //尾款是否已付
	isDepositPayed: false, //定金是否已付
	user: {
		id: "user001", //用户id
		name: "张三", //用户姓名
		mobile: "186655454552", //用户联系方式
	},
	location: [32.573306, 116.787141], //定位经纬度
	address: "安徽省寿县寿春镇XXX", //定位地址
	remark: "", //备注
	serviceTime: "2024-04-06 至 2024-04-27" //期望服务时间
},
{
	orderId: "SZNFFW202403301002", //订单ID
	createTime: "2024-02-28 10:22:14", //创建时间
	status: 1, //订单状态  1: '待派单', 10: '待确认', 2: '服务中', 3: '待支付', 99: '已完成', -99: '已取消'
	services: [{
		id: 1, //id
		name: "玉米收割", //服务名称
		type: "收割", //服务类型
		price: "130", //服务单价
		variety: ["玉米"], //服务品种
		image: "http://www.zgnf.net/profile/resources/2022/uploads/shhfw/products/20221216/3a4bc35aada050c19c2241993dad08e7.jpg", //服务图片
		description: "玉米收割", //服务描述
		machine: {
			id: "machine001", //农机ID
			name: "玉米收割机001" //农机名称
		},
		driver: {
			id: "dri001", //农机手ID
			name: "张三", //农机手名称
			mobile: "18655457505" //农机手电话
		},
		status: 1
	}],
	number: 1, //预计面积，亩
	estimateTotal: 130, //预估价格
	actualArea: null, //实际测量面积，亩
	isFinalPayed: false, //尾款是否已付
	isDepositPayed: true, //定金是否已付
	user: {
		id: "user001", //用户id
		name: "", //用户姓名
		mobile: "186655454552", //用户联系方式
	},
	location: [32.573306, 116.787141], //定位经纬度
	address: "安徽省寿县寿春镇XXX", //定位地址
	remark: "", //备注
	serviceTime: "2024-04-06 至 2024-04-27" //期望服务时间
},
{
	orderId: "SZNFFW202403301003", //订单ID
	createTime: "2024-02-28 10:22:14", //创建时间
	status: 1, //订单状态  1: '待派单', 10: '待确认', 2: '服务中', 3: '待支付', 99: '已完成', -99: '已取消'
	services: [{
		id: 1, //id
		name: "玉米收割", //服务名称
		type: "收割", //服务类型
		price: "130", //服务单价
		variety: ["玉米"], //服务品种
		image: "http://www.zgnf.net/profile/resources/2022/uploads/shhfw/products/20221216/3a4bc35aada050c19c2241993dad08e7.jpg", //服务图片
		description: "玉米收割", //服务描述
		machine: {
			id: "machine001", //农机ID
			name: "玉米收割机001" //农机名称
		},
		driver: {
			id: "dri001", //农机手ID
			name: "张三", //农机手名称
			mobile: "18655457505" //农机手电话
		},
		status: 3
	}],
	number: 1, //预计面积，亩
	estimateTotal: 130, //预估价格
	actualArea: null, //实际测量面积，亩
	isFinalPayed: false, //尾款是否已付
	isDepositPayed: true, //定金是否已付
	user: {
		id: "user001", //用户id
		name: "", //用户姓名
		mobile: "186655454552", //用户联系方式
	},
	location: [32.573306, 116.787141], //定位经纬度
	address: "安徽省寿县寿春镇XXX", //定位地址
	remark: "", //备注
	serviceTime: "2024-04-06 至 2024-04-27" //期望服务时间
},
{
	orderId: "SZNFFW202403301004", //订单ID
	createTime: "2024-02-28 10:22:14", //创建时间
	status: 2, //订单状态  1: '待派单', 10: '待确认', 2: '服务中', 3: '待支付', 99: '已完成', -99: '已取消'
	services: [{
		id: 1, //id
		name: "玉米收割", //服务名称
		type: "收割", //服务类型
		price: "130", //服务单价
		variety: ["玉米"], //服务品种
		image: "http://www.zgnf.net/profile/resources/2022/uploads/shhfw/products/20221216/3a4bc35aada050c19c2241993dad08e7.jpg", //服务图片
		description: "玉米收割", //服务描述
		machine: {
			id: "machine001", //农机ID
			name: "玉米收割机001" //农机名称
		},
		driver: {
			id: "dri001", //农机手ID
			name: "张三", //农机手名称
			mobile: "18655457505" //农机手电话
		},
		status: 3
	}],
	number: 1, //预计面积，亩
	estimateTotal: 130, //预估价格
	actualArea: null, //实际测量面积，亩
	isFinalPayed: false, //尾款是否已付
	isDepositPayed: true, //定金是否已付
	user: {
		id: "user001", //用户id
		name: "", //用户姓名
		mobile: "186655454552", //用户联系方式
	},
	location: [32.573306, 116.787141], //定位经纬度
	address: "安徽省寿县寿春镇XXX", //定位地址
	remark: "", //备注
	serviceTime: "2024-04-06 至 2024-04-27" //期望服务时间
},
{
	orderId: "SZNFFW202403301005", //订单ID
	createTime: "2024-02-28 10:22:14", //创建时间
	status: 3, //订单状态  1: '待派单', 10: '待确认', 2: '服务中', 3: '待支付', 99: '已完成', -99: '已取消'
	services: [{
		id: 1, //id
		name: "玉米收割", //服务名称
		type: "收割", //服务类型
		price: "130", //服务单价
		variety: ["玉米"], //服务品种
		image: "http://www.zgnf.net/profile/resources/2022/uploads/shhfw/products/20221216/3a4bc35aada050c19c2241993dad08e7.jpg", //服务图片
		description: "玉米收割", //服务描述
		machine: {
			id: "machine001", //农机ID
			name: "玉米收割机001" //农机名称
		},
		driver: {
			id: "dri001", //农机手ID
			name: "张三", //农机手名称
			mobile: "18655457505" //农机手电话
		},
		status: 3
	}],
	number: 1, //预计面积，亩
	estimateTotal: 130, //预估价格
	actualArea: 1.5, //实际测量面积，亩
	isFinalPayed: false, //尾款是否已付
	isDepositPayed: true, //定金是否已付
	user: {
		id: "user001", //用户id
		name: "", //用户姓名
		mobile: "186655454552", //用户联系方式
	},
	location: [32.573306, 116.787141], //定位经纬度
	address: "安徽省寿县寿春镇XXX", //定位地址
	remark: "", //备注
	serviceTime: "2024-04-06 至 2024-04-27" //期望服务时间
},
{
	orderId: "SZNFFW202403301006", //订单ID
	createTime: "2024-02-28 10:22:14", //创建时间
	status: 99, //订单状态  1: '待派单', 10: '待确认', 2: '服务中', 3: '待支付', 99: '已完成', -99: '已取消'
	services: [{
		id: 1, //id
		name: "玉米收割", //服务名称
		type: "收割", //服务类型
		price: "130", //服务单价
		variety: ["玉米"], //服务品种
		image: "http://www.zgnf.net/profile/resources/2022/uploads/shhfw/products/20221216/3a4bc35aada050c19c2241993dad08e7.jpg", //服务图片
		description: "玉米收割", //服务描述
		machine: {
			id: "machine001", //农机ID
			name: "玉米收割机001" //农机名称
		},
		driver: {
			id: "dri001", //农机手ID
			name: "张三", //农机手名称
			mobile: "18655457505" //农机手电话
		},
		status: 3
	}],
	number: 1, //预计面积，亩
	estimateTotal: 130, //预估价格
	actualArea: 1.5, //实际测量面积，亩
	isFinalPayed: true, //尾款是否已付
	isDepositPayed: true, //定金是否已付
	user: {
		id: "user001", //用户id
		name: "", //用户姓名
		mobile: "186655454552", //用户联系方式
	},
	location: [32.573306, 116.787141], //定位经纬度
	address: "安徽省寿县寿春镇XXX", //定位地址
	remark: "", //备注
	serviceTime: "2024-04-06 至 2024-04-27" //期望服务时间
},
{
	orderId: "SZNFFW202403301007", //订单ID
	createTime: "2024-02-28 10:22:14", //创建时间
	status: -99, //订单状态  1: '待派单', 10: '待确认', 2: '服务中', 3: '待支付', 99: '已完成', -99: '已取消'
	services: [{
		id: 1, //id
		name: "玉米收割", //服务名称
		type: "收割", //服务类型
		price: "130", //服务单价
		variety: ["玉米"], //服务品种
		image: "http://www.zgnf.net/profile/resources/2022/uploads/shhfw/products/20221216/3a4bc35aada050c19c2241993dad08e7.jpg", //服务图片
		description: "玉米收割", //服务描述
		machine: {
			id: "machine001", //农机ID
			name: "玉米收割机001" //农机名称
		},
		driver: {
			id: "dri001", //农机手ID
			name: "张三", //农机手名称
			mobile: "18655457505" //农机手电话
		}
	}],
	number: 1, //预计面积，亩
	estimateTotal: 130, //预估价格
	actualArea: 1.5, //实际测量面积，亩
	isFinalPayed: false, //尾款是否已付
	isDepositPayed: false, //定金是否已付
	user: {
		id: "user001", //用户id
		name: "", //用户姓名
		mobile: "186655454552", //用户联系方式
	},
	location: [32.573306, 116.787141], //定位经纬度
	address: "安徽省寿县寿春镇XXX", //定位地址
	remark: "", //备注
	serviceTime: "2024-04-06 至 2024-04-27" //期望服务时间
},
];



const shopOrderList = [{
	"orderId": "SZNFFW202403301001",
	"createTime": "2024-02-28 10:22:14",
	"userId": "163ca3e18d97028a02b91e970b5919ec720d6871rb0r839slpd7",
	"status": "1",
	"product": {
		"id": "1682582320604",
		"name": "五洲丰控失肥",
		"image": "http://www.zgnf.net/profile/resources/2021/uploads/shhfw/products/20210412/96246d3d292ac56c919145e7d4718f4e.jpg",
		"description": "五洲丰控失肥",
		"attributes": ["50公斤/袋"],
		"category": "肥料",
		"unit": "袋",
		"price": 130
	},
	"number": 80,
	"toAddress": {
		"name": "孙先生",
		"province": "340000",
		"provinceStr": "安徽省",
		"city": "340100",
		"cityStr": "合肥市",
		"area": "340104",
		"areaStr": "蜀山区",
		"street": "ddsdsdasd",
		"mobile": "18655457505"
	},
	"remark": "",
	"expressNo": null,
	"expressId": null,
	"client": "H5",
	"serviceTime": "2024-04-27"
}];



const farmOrderList = [{
	"orderId": "SZNFNC202403301001",
	"createTime": "2024-02-28 10:22:14",
	"userId": "163ca3e18d97028a02b91e970b5919ec720d6871rb0r839slpd7",
	"status": "1",
	"product": {
		"id": "1682582320604",
		"name": "皖西白鹅",
		"image": "https://photo.tuchong.com/16199987/f/1222778845.jpg",
		"description": "1333",
		"category": "白鹅",
		"price": 1000,
		"unit": "份"
	},
	"completeTime": "2024-04-28",
	"number": 1,
	"remark": "",
	"client": "H5"
}, {
	"orderId": "SZNFNC202403301002",
	"createTime": "2024-02-28 10:22:14",
	"userId": "163ca3e18d97028a02b91e970b5919ec720d6871rb0r839slpd7",
	"status": "2",
	"product": {
		"id": "1682582320604",
		"name": "皖西白鹅",
		"image": "https://photo.tuchong.com/16199987/f/1222778845.jpg",
		"description": "1333",
		"category": "白鹅",
		"price": 1000,
		"unit": "份"
	},
	"completeTime": "2024-04-28",
	"number": 3,
	"remark": "",
	"client": "H5"
}];


const expressList = [{
	"value": 1,
	"label": "顺丰",
}];



const goodsList = [{
	"id": "1682582320604",
	"name": "五洲丰控失肥",
	"image": "http://www.zgnf.net/profile/resources/2021/uploads/shhfw/products/20210412/96246d3d292ac56c919145e7d4718f4e.jpg",
	"description": "五洲丰控失肥",
	"attributes": "50公斤/袋",
	"categoryString": "肥料",
	"categoryId": "1",
	"unit": "袋",
	"price": 130,
	"createTime": "2024-04-11 10:00:00",
	"min": 80,
	"state": true,
	"supplier": "1",
	"brand": "未知"
},
{
	"id": "1682582320604",
	"name": "立奇",
	"image": "http://www.zgnf.net/profile/resources/2021/uploads/shhfw/products/20220421/b58bb8cc0d4c2df08fb19a39f893a70e.jpg",
	"description": "立奇",
	"attributes": "500g/瓶",
	"categoryString": "农药",
	"categoryId": "2",
	"unit": "瓶",
	"price": 80,
	"createTime": "2024-04-11 10:00:00",
	"min": 80,
	"state": true,
	"supplier": "1",
	"brand": "未知"
}
];

const attributeList = [{
	"id": "1",
	"value": "1",
	"label": "50公斤/袋",
},
{
	"id": "2",
	"value": "2",
	"label": "500g/瓶",
}
];

const categoryList = [{
	"id": "1",
	"value": "1",
	"label": "化肥",
},
{
	"id": "2",
	"value": "2",
	"label": "农药",
}
];

const supplierList = [{
	"id": "1",
	"name": "淮南农业服务有限公司",
	"mobile": "12345xx22234",
	"address": "安徽省淮南市蔡家岗8888号",
	"state": true
}];

const farmList = [{
	id: 1,
	name: "寿西湖农场",
	image: "https://img1.baidu.com/it/u=649366986,3165974053&fm=253&fmt=auto&app=138&f=JPEG?w=864&h=486",
	description: "寿西湖农场位于国家历史文化名城--安徽省寿县西门外，常年种植小麦和黄豆，这里地域非常广阔，北临淮河并引淮河水灌溉，有完善的灌溉渠体系和将近全机械化式的种植收割系统，此外还拥有小型飞机场，利用飞机喷洒农药，寿西湖农场现有耕地5.8万亩，具有完整的农业技术推广和良种繁育、加工、销售体系，旱田机械化程度达95%以上，年国民生产总值达9000万元，是全国农垦百家种子生产基地之一。 坐落在寿县的寿西湖农场，拥有麦、豆原良种繁殖基地6万亩，为全国种子项目区之一。",
	products: [{
		id: 1,
		image: "https://photo.tuchong.com/16199987/f/1222778845.jpg",
		name: "皖西白鹅",
		description: "皖西白鹅是中国优良的中型鹅品种之一，其形成距今已有400多年历史，具有早期生长速度快、抗逆性强，肉质细嫩鲜美等优点，尤其是以皖西白鹅的羽绒朵大绒长、蓬松度好享誉国际市场。 [11]并且，于2021年4月，皖西白鹅被选入2021年第一批全国名特优新农产品名录中。",
		category: "白鹅",
		price: 1000,
		mobile: "17777777777",
		"createTime": "2024-04-11 10:00:00",
		state: true
	},
	{
		id: 2,
		image: "https://www.ahnews.com.cn/wh/pc/pic/2020-12/10/280460_1e260f2e-3247-4960-8b2b-6756d5fa410b.jpg",
		name: "寿霍黑猪",
		description: "寿霍黑猪生产历史悠久，是安徽省优良地方品种之一，主要分布在寿县、霍邱、金寨、长丰、定远、淮南。该猪主要特征是体型中等，偏于肉用型，全身被毛黑色，身腰较长，嘴筒长直，鼻吻特别发达。肉质好，肌纤维细、肌间脂肪沉积能力强，肉质好。耐粗饲，抗病力强，繁殖性能好",
		category: "黑猪",
		price: 1000,
		mobile: "17777777777",
		"createTime": "2024-04-11 10:00:00",
		state: true
	}
	],
	videos: [{
		id: 1,
		name: "监控1",
		src: 'http://1254333170.vod2.myqcloud.com/7898b9fcvodgzp1254333170/d371a6e2387702295049867457/YTiHGQhkFPcA.mp4',
	}, {
		id: 2,
		name: "监控2",
		src: 'http://1254333170.vod2.myqcloud.com/7898b9fcvodgzp1254333170/cca6c8bd387702295049594248/rdLor36jdCYA.mp4',
	}],
	area: 1234,
	contactName: "张三",
	mobile: "121345xx123123",
	address: "安徽省淮南市寿县跃进路",
	createTime: "2024-02-28 10:22:14",
	state: true
}]

const farm_goodsList = [{
	id: 1,
	image: "https://photo.tuchong.com/16199987/f/1222778845.jpg",
	name: "皖西白鹅",
	description: "皖西白鹅是中国优良的中型鹅品种之一，其形成距今已有400多年历史，具有早期生长速度快、抗逆性强，肉质细嫩鲜美等优点，尤其是以皖西白鹅的羽绒朵大绒长、蓬松度好享誉国际市场。 [11]并且，于2021年4月，皖西白鹅被选入2021年第一批全国名特优新农产品名录中。",
	category: "白鹅",
	price: 1000,
	mobile: "17777777777",
	"createTime": "2024-04-11 10:00:00",
	state: true
},
{
	id: 2,
	image: "https://www.ahnews.com.cn/wh/pc/pic/2020-12/10/280460_1e260f2e-3247-4960-8b2b-6756d5fa410b.jpg",
	name: "寿霍黑猪",
	description: "寿霍黑猪生产历史悠久，是安徽省优良地方品种之一，主要分布在寿县、霍邱、金寨、长丰、定远、淮南。该猪主要特征是体型中等，偏于肉用型，全身被毛黑色，身腰较长，嘴筒长直，鼻吻特别发达。肉质好，肌纤维细、肌间脂肪沉积能力强，肉质好。耐粗饲，抗病力强，繁殖性能好",
	category: "黑猪",
	price: 1000,
	mobile: "17777777777",
	"createTime": "2024-04-11 10:00:00",
	state: true
}
]

const memberList = [{
	id: "user001",
	name: "王二",
	mobile: "18655457505",
	createTime: "2024-04-11 10:00:00",
	state: true,
},
{
	id: "user002",
	name: "赵四",
	mobile: "18655457505",
	createTime: "2024-04-11 10:00:00",
	state: true,
}
]

const newsList = [{
	id: 1,
	createTime: '2024-04-13 10:00:00',
	category: '地方新闻',
	title: '3D农业农村部推介2023年全国农业社会化服务典型案例？',
	subtitle: '农业农村部办公厅关于推介2023年全国农业社会化服务典型案例的通知近年来，各地深入贯彻落实党中央、国务院关于发展农业社会化服务的决策部署',
	detail: '农业农村部办公厅关于推介2023年全国农业社会化服务典型案例的通知近年来，各地深入贯彻落实党中央、国务院关于发展农业社会化服务的决策部署，持续加大引导、推',
	image: 'http://www.zgnf.net/profile/resources/2022/uploads/shhfw/message/20231107/6c279a52339af51ea4e3aa27fb9c455a.png',
	viewCount: 567
},
{
	id: 2,
	createTime: '2024-04-13 10:00:00',
	category: '政策解读',
	title: '农业社会化服务体系地位作用与高质量发展？',
	subtitle: '习近平总书记指出，“创新农业经营方式，发展规模化经营、社会化服务。”',
	detail: '习近平总书记指出，“创新农业经营方式，发展规模化经营、社会化服务。”党的二十大报告指出，“巩固和完善农村基本经营制度，发展新型农村集体经济，发展新型农业经营？',
	image: 'http://www.zgnf.net/profile/resources/2022/uploads/shhfw/message/20240115/ac5b2285bbcd20c6bde82f91e1cb50f3.jpg',
	viewCount: 654
}
]

let processList = [{
	name: "在线预约",
	createTime: "2024-04-19 10:00:00",
	description: "用户已预约，预约服务时间2024-04-21 ~ 2024-04-26",
},
{
	name: "平台确认",
	createTime: "2024-04-19 10:01:00",
	description: "平台已确认订单，待派单",
},
{
	name: "服务派单",
	createTime: "2024-04-21 08:00:00",
	description: "灭茬服务已派单",
	machine: {
		id: "m001",
		name: "灭茬机"
	},
	driver: {
		id: "dri001",
		name: "张师傅",
		mobile: "18655458525"
	}
},
{
	name: "开始作业",
	createTime: "2024-04-21 09:00:00",
	description: "灭茬服务已开始作业",
	machine: {
		id: "m001",
		name: "灭茬机"
	},
	driver: {
		id: "dri001",
		name: "张师傅",
		mobile: "18655458525"
	}
},
{
	name: "作业进度",
	createTime: "2024-04-21 09:00:00",
	description: "灭茬服务作业已完成2亩",
	records: [
		"http://www.zgnf.net/profile/resources/2022/uploads/shhfw/products/20240102/970e3cb7779bac0e5fb4a50d80cb3d7b.jpg",
		"https://ledong.hainan.gov.cn/attachment/cmsfile/ledong/26913/201711/daofile/29484W020171130330822564220.jpg"
	],
	machine: {
		id: "m001",
		name: "灭茬机"
	},
	driver: {
		id: "dri001",
		name: "张师傅",
		mobile: "18655458525"
	}
},
{
	name: "结束作业",
	createTime: "2024-04-21 09:00:00",
	description: "灭茬服务已完成",
	machine: {
		id: "m001",
		name: "灭茬机"
	},
	driver: {
		id: "dri001",
		name: "张师傅",
		mobile: "18655458525"
	}
}
]

const transactionList = [{
	"id": "658148048291893248",
	"createTime": "2024-04-29 23:21:58",
	"updateTime": "2024-04-29 23:21:58",
	"statusString": "成功",
	"typeString": "农机服务支付",
	"orderId": "646011111162646528",
	"note": "",
	"userId": "646010655057252352",
	"name": "高冉",
	"phone": "199****5293",
	"type": "SERVICE",
	"amount": "1074.89",
	"refunded": false,
	"payMethod": "微信",
	"clientType": "ALIPAY",
	"status": "SUCCESS"
},
{
	"id": "658148048291893231",
	"createTime": "2024-04-29 23:21:58",
	"updateTime": "2024-04-29 23:21:58",
	"statusString": "成功",
	"typeString": "农机服务支付",
	"orderId": "646011111162646512",
	"note": "",
	"userId": "646010655057252352",
	"name": "高冉",
	"phone": "199****5293",
	"type": "SERVICE",
	"amount": "1074.89",
	"refunded": false,
	"payMethod": "线下支付",
	"clientType": "ALIPAY",
	"status": "SUCCESS"
}
]


let landList = [
	{
		id: 1,
		name: "瓦埠镇上冲村",
		level: 1,
		parentId: null,
		area: 500.1,
		leased: 200.1,
		state: true,
		children: [{
			id: "1-1",
			level: 2,
			parentId: 1,
			name: "顾岗西1",
			area: 126.19,
			leased: 126.19,
			state: true
		}, {
			id: "1-2",
			level: 2,
			parentId: 1,
			name: "顾岗东1",
			area: 62.50,
			leased: 0,
			state: true
		}, {
			id: "1-3",
			level: 2,
			parentId: 1,
			name: "汤庄西",
			area: 61.76,
			leased: 0,
			state: true
		}]
	}
];

let landLeaseList = [
	{
		id: "1",
		landName: "顾岗西1",
		landId: "1-1",
		area: 200,
		leaserName: "张三",
		mobile: "18655457505",
		leaseDateStart: "2024-07-01",
		leaseDateEnd: "2027-07-01",
		contract: ""
	}
];

export {
	menus,
	services,
	serviceTypeList,
	serviceVarietyList,
	machines,
	drivers,
	serviceOrderList,
	shopOrderList,
	expressList,
	farmOrderList,
	goodsList,
	attributeList,
	supplierList,
	farmList,
	farm_goodsList,
	memberList,
	newsList,
	processList,
	transactionList,
	categoryList,
	landList,
	landLeaseList
}