<template>
  <div class="charts" ref="charts"></div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";

export default {
  props: {
    chartData: Array,
  },
  // computed: {
  //   ...mapState({
  //     listState: (state) => state.home.list,
  //   }),
  // },
  data() {
    return {
      lineCharts: null,
    };
  },
  mounted() {
    this.lineCharts = echarts.init(this.$refs.charts);
    this.lineCharts.setOption({
      xAxis: {
        show: false,
        type: "category",
      },
      yAxis: {
        show: false,
      },
      series: [
        {
          type: "bar",
          data: [],
        },
      ],
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      tooltip: {
        // formatter: function (params) {
        //   console.log(params)
        //     return `${params.seriesName}:${params.value}`;
        // }
      },
    });
  },
  watch: {
    chartData: {
      handler(nval, oval) {
        if (this.chartData) {
          let times = [];
          let datas = [];
          for (let i = 0; i < this.chartData.length; i++) {
            let item = this.chartData[i];
            times.push(item.OrderDate);
            datas.push(this.$round(item.total));
          }
          // let data = this.chartData.map((item) => {
          //   return this.$round(item.total);
          // });
          this.lineCharts.setOption({
            xAxis: {
              data: times,
            },
            series: [
              {
                data: datas,
              },
            ],
          });
        }
      },
      deep: true,
      immediate: false
    },
  },
};
</script>

<style>
.charts {
  width: 100%;
  height: 100%;
}
</style>
