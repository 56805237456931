<template>
  <div>
    <el-table :data="payment_list" border stripe>
      <el-table-column width="150px" label="付款凭证">
        <template slot-scope="scope">
          <div style="position: relative; width: 150px; height: 80px">
            <el-image
              v-for="(item, index) in scope.row.payment_evidence"
              :key="index"
              :style="{
                width: '80px',
                height: '80px',
                position: 'absolute',
                'z-index': 10,
                'margin-left': index * 20 + 'px',
                border: '1px solid #999',
              }"
              :src="$imageResize(item, 100)"
              :preview-src-list="scope.row.payment_evidence"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>

      <el-table-column width="150px" label="付款金额">
        <template slot-scope="scope">
          <el-tag type="success">¥{{ $round(scope.row.amount) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="150px" label="付款方式">
        <template slot-scope="scope">
          {{ scope.row.method == "offline" ? "用户转账" : "在线支付" }}
        </template>
      </el-table-column>
      <el-table-column width="200px" label="付款时间">
        <template slot-scope="scope">
          {{
            scope.row.pay_date
              ? new Date(scope.row.pay_date).Format("yyyy年MM月dd日")
              : "--"
          }}
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">{{ scope.row.remark }}</template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="200px">
        <template slot-scope="scope">
          <!-- <el-button type="primary" icon="el-icon-edit" size="mini" @click="editInfo(scope.row)">编辑</el-button> -->
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="removeById(scope.row.payment_id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="更新付款凭证"
      :visible.sync="offlinePayDialogVisible"
      width="40%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      append-to-body
      modal-append-to-body
    >
      <AddPayment
        :detail="currentInfo"
        :isEdit="true"
        :orderNo="orderNo"
        @refreshList="refreshList"
      ></AddPayment>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import upload from "@/components/upload";
import AddPayment from "./AddPayment.vue";
export default {
  components: {
    upload,
    AddPayment,
  },
  props: {
    orderNo: String,
  },
  data() {
    return {
      payment_list: [],
      currentInfo: "",
      isEdit: true,
      offlinePayDialogVisible: false,
    };
  },
  computed: {},
  watch: {
    orderNo: {
      immediate: true,
      handler(nval) {
        if (nval) {
          // this.getPaymentList();
        }
      },
    },
  },
  created() {
    let that = this;
  },
  mounted() {},
  methods: {
    refreshList() {
      // this.offlinePayDialogVisible = false;
      this.getPaymentList();
      // this.$emit("refreshList");
    },
    async getPaymentList() {
      this.payment_list = [];
      const { data: res } = await this.$http.get("/web_api/shop/payment_list", {
        params: {
          orderNo: this.orderNo,
        },
      });
      if (res.code !== 200) {
        return this.$message.error("获取付款凭证列表失败！");
      }
      res.data.forEach((element) => {
        element.payment_evidence = element.payment_evidence.split(",");
        this.payment_list.push(element);
      });
    },
    editInfo(row) {
      this.currentInfo = row;
      this.offlinePayDialogVisible = true;
    },
    removeById(ID) {
      this.$confirm("此操作将永久删除该付款凭证, 是否继续?", "温馨提示", {
        confirmButtonText: "确定",
        callback: async (action) => {
          if (action == "confirm") {
            const { data: res } = await this.$http.post(
              "web_api/shop/delete_payment",
              {
                id: ID,
                orderNo: this.orderNo,
              }
            );
            if (res.code !== 200) {
              return this.$message.error("操作失败！");
            }
            this.$message.success("操作成功！");
            this.getPaymentList();
            this.$emit("refreshList");
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
</style>
