<template>
  <div>
    <el-form
      :model="offlinePayForm"
      ref="offlinePayFormRef"
      label-width="100px"
    >
      <el-form-item label="付款凭证">
        <upload
          name="pay_proof_image"
          :initFileList="initFileList"
          @handleUploadSuccess="handleUploadSuccess"
          @handleRemove="handleRemove"
        ></upload>
      </el-form-item>
      <el-form-item label="付款日期" required>
        <el-date-picker
          v-model="offlinePayForm.pay_date"
          align="right"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="付款金额">
        <el-input
          v-model="offlinePayForm.amount"
          placeholder="在此输入付款金额"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="offlinePayForm.remark"
          placeholder="在此输入备注信息"
        ></el-input>
      </el-form-item>
      <el-button :loading="isApply" type="primary" @click="offlinePayConfirm"
        >确 定</el-button
      >
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";
import upload from "@/components/upload";
export default {
  components: {
    upload,
  },
  props: {
    orderNo: String,
    detail: Object,
  },
  data() {
    return {
      isApply: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      initFileList: [],
      offlinePayForm: {
        order_no: "",
        payment_evidence: "",
        pay_date: "",
        amount: "",
        remark: "",
      },
      isMultiple: true,
    };
  },
  computed: {},
  watch: {
    detail: {
      handler(nval, oval) {
        if (nval) {
          let _newObj = _.cloneDeep(nval);
          for (let key in this.offlinePayForm) {
            this.offlinePayForm[key] = _newObj[key];
          }
          if (this.offlinePayForm.payment_evidence) {
            this.initFileList = [
              {
                url: this.offlinePayForm.payment_evidence,
              },
            ];
          } else {
            this.initFileList = [];
          }
        } else {
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    let that = this;
  },
  mounted() {},
  methods: {
    handleRemove(response) {
      if (response && response.name == "pay_proof_image") {
        this.offlinePayForm.payment_evidence = "";
      }
    },
    // 监听图片上传成功事件
    handleUploadSuccess(response) {
      console.log("--------------");
      console.log(response);
      if (response && response.name == "pay_proof_image") {
        let images = response.value.map((item) => {
          return item.url;
        });
        this.offlinePayForm.payment_evidence = images.join(",");
      }
    },
    async offlinePayConfirm() {
      try {
        if (!this.offlinePayForm.payment_evidence) {
          return this.$message.error("请上传支付凭证");
        }

        if (!this.offlinePayForm.pay_date) {
          return this.$message.error("请选择付款日期");
        }

        if (this.offlinePayForm.amount <= 0) {
          return this.$message.error("支付金额有误");
        }

        let params = _.cloneDeep(this.offlinePayForm);
        params.order_no = this.orderNo;
        params.amount = params.amount * 100;
        this.isApply = true;
        const { data: res } = await this.$http.post(
          "web_api/shop/offline_pay",
          params
        );
        this.isApply = false;
        if (res.code !== 200) {
          return this.$message.error(res.msg || "操作失败！");
        }
        this.$message.success("操作成功！");
        this.offlinePayForm = {};
        this.offlinePayDialogVisible = false;
        this.$emit("refreshList", "payment");
      } catch (err) {
        this.isApply = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
</style>
