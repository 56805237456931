<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订餐订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="6">
          <el-input placeholder="请输入内容">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row>

      <!-- 订单列表 -->
      <el-table :data="list" border stripe>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="" v-for="(item, index) in props.row.specialtys">
                <div style="display: flex; line-height: 100px">
                  <img
                    :src="item.specialty_image"
                    width="100px"
                    height="100px"
                    style="margin-left: 30px"
                  />
                  <div style="margin-left: 30px">{{ item.specialty_name }}</div>
                  <div style="margin-left: 30px; color: red">
                    单价：￥{{ $round(item.specialty_price) }}元
                  </div>
                  <div style="margin-left: 30px; color: red">
                    x{{ item.specialty_number }}
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          label="订单编号"
          width="250px"
          prop="order_no"
        ></el-table-column>
        <el-table-column width="100px" label="订单总额(元)">
          <template slot-scope="scope">
            <el-tag type="primary">{{ $round(scope.row.order_price) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="200px" label="下单时间">
          <template slot-scope="scope">{{
            scope.row.create_time | dataFormat
          }}</template>
        </el-table-column>
        <el-table-column width="150px" label="当前状态">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.status == 0" size="mini">{{
              getStatus(scope.row.status)
            }}</el-tag>

            <el-tag
              v-else-if="scope.row.status == -99"
              type="info"
              size="mini"
              >{{ getStatus(scope.row.status) }}</el-tag
            >

            <el-tag type="success" v-else size="mini">{{
              getStatus(scope.row.status)
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="200px" label="备注">
          <template slot-scope="scope">
            {{ scope.row.remark }}
          </template>
        </el-table-column>
        <el-table-column width="200px" label="订餐联系人">
          <template slot-scope="scope">
            <el-tag type="primary">{{ getContact(scope.row.address) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="配送地址">
          <template slot-scope="scope">
            {{ getFullAddress(scope.row.address) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="400px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-close"
              @click="cancelOrder(scope.row)"
              v-if="scope.row.status != -99"
              >取消订单</el-button
            >
            <el-button
              type="success"
              size="mini"
              icon="el-icon-location"
              @click="dispatchOrder(scope.row)"
              v-if="scope.row.status == 1"
              >接受订单</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <el-dialog title="取消订单" :visible.sync="cancelOrderVisible" width="50%">
      <el-form ref="dispatchOrderRef" label-width="100px">
        <el-form-item label="取消原因">
          <el-input
            v-model="cancelReason"
            placeholder="在此输入取消的原因"
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="cancelOrderConfirm">确 定</el-button>
      </el-form>
    </el-dialog>

    <el-dialog
      title="配送订单"
      :visible.sync="dispatchOrderVisible"
      width="50%"
    >
      <el-form ref="dispatchOrderRef" label-width="100px">
        <el-form-item label="配送凭证">
          <upload
            name="dispatch_proof_image"
            initFileList="[]"
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
        <el-button type="primary" @click="dispatchOrderConfirm"
          >确 定</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import cityData from "@/config/citydata.js";
import { farmOrderList } from "@/config/config.js";
import { canteenOrderStatus } from "@/config/index.js";
import _ from "lodash";
export default {
  data() {
    return {
      // 订单列表查询参数
      queryInfo: {
        query: "",
        page: 1,
        limit: 10,
        status: -1,
      },
      total: 0,
      list: [],
      cancelOrderVisible: false,
      dispatchOrderVisible: false,
      currentOrder: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getContact(json) {
      let address = JSON.parse(json);
      console.log(address);
      console.log(address);
      return `${address.name}(${address.mobile})`;
    },
    getFullAddress(json) {
      let address = JSON.parse(json);
      console.log(address);
      return (
        address.province_str +
        address.city_str +
        address.area_str +
        address.street
      );
    },
    getStatus(status) {
      return canteenOrderStatus[status];
    },
    async getList() {
      let params = _.cloneDeep(this.queryInfo);
      params.page--;
      const { data: res } = await this.$http.get("web_api/canteen/allOrder", {
        params,
      });
      if (res.code !== 200) {
        return this.$message.error("获取订单列表失败！");
      }
      this.list = res.data;
      this.total = res.total || this.list.length;
    },
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getList();
    },
    handleCurrentChange(newSize) {
      this.queryInfo.page = newSize;
      this.getList();
    },
    cancelOrder(row) {
      this.currentOrder = row;
      this.cancelOrderVisible = true;
    },
    dispatchOrder(row) {
      this.currentOrder = row;
      this.dispatchOrderVisible = true;
    },
    async cancelOrderConfirm(order_no) {
      const { data: res } = await this.$http.post("web_api/canteen/cancelOrder", {
        order_no,
      });
      if (res.code !== 200) return this.$message.error("操作失败！");
      this.$message.success("操作成功!");
      this.getList();
    },
    async dispatchOrderConfirm(order_no) {
      const { data: res } = await this.$http.post("web_api/canteen/acceptOrder", {
        order_no,
      });
      if (res.code !== 200) return this.$message.error("操作失败！");
      this.$message.success("操作成功!");
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
</style>
