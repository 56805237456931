<template>
  <div>
    <!-- 面包屑导航区 -->
    <!--   <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农场管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/products_farm' }"
        >土特产列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>添加土特产</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 卡片视图 -->
    <el-card>
      <!-- 提示 -->
      <el-alert
        title="添加土特产信息"
        type="info"
        center
        show-icon
        :closable="false"
      ></el-alert>
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
        label-position="top"
      >
        <el-form-item label="土特产名称">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="土特产价格">
          <el-input v-model="addForm.price"></el-input>
        </el-form-item>
        <el-form-item label="土特产规格">
          <el-input
            v-model="addForm.specification"
            placeholder="请输入产品规格"
          ></el-input>
        </el-form-item>
        <el-form-item label="库存数量(份)">
          <el-input v-model="addForm.inventory" type="number"></el-input>
        </el-form-item>
        <!-- <el-form-item label="计量单位">
          <el-input v-model="addForm.unit" type="text"></el-input>
        </el-form-item> -->
        <el-form-item label="土特产图片">
          <upload
            name="mainImage"
            :initFileList="initFileList"
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
        <el-form-item label="土特产详情">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 20 }"
            placeholder="土特产详情"
            v-model="addForm.description"
          >
          </el-input>
        </el-form-item>
        <el-button type="primary" class="btnAdd" @click="addAction"
          >添加土特产</el-button
        >
      </el-form>
    </el-card>
  </div>
</template>

<script>
import _ from "lodash";
import { attributeList, supplierList } from "@/config/config.js";
import upload from "@/components/upload";
export default {
  components: {
    upload,
  },
  props: {
    detail: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    detail: {
      handler(nval, oval) {
        if(!nval){
          return
        }
        // this.addForm = nval || {};
        this.addForm = nval ? _.cloneDeep(nval) : {};
        this.addForm.price = this.addForm.price ? this.$round( this.addForm.price ) : 0;
        if (this.addForm.image) {
          this.initFileList = [
            {
              url: this.addForm.image,
            },
          ];
        } else {
          this.initFileList = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      addForm: {
        name: "",
        specification: "",
        image: "",
        inventory:"",
        price: "",
        description: "",
        // unit: "",
        farm_id: "",
      },
      dateRange: "",
      addFormRules: {
        name: [
          {
            required: true,
            message: "请输入土特产名称",
            trigger: "blur",
          },
        ],
        specification: [
          {
            required: true,
            message: "请选择土特产规格",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "请选择土特产价格",
            trigger: "blur",
          },
        ],
        image: [
          {
            required: true,
            message: "请上传土特产照片",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "请填写土特产介绍",
            trigger: "blur",
          },
        ],
      },
      initFileList: [],
    };
  },
  created() {},
  computed: {
    
  },
  methods: {
    // 级联选择器选中项变化时出发
    handleChange() {},
    // 监听图片上传成功事件
    handleRemove() {
      this.addForm.image = "";
    },
    // 监听图片上传成功事件
    handleUploadSuccess(response) {
      this.addForm.image = response.value[0].url;
    },
    // 添加土特产
    addAction() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return this.$message.error("请填写必要的表单项！");
        let params = _.cloneDeep(this.addForm);
        params.price = params.price * 100;
        let url = "web_api/specialty/addFood";
        if (this.isEdit) {
          params.id = this.detail.id;
          url = "web_api/specialty/updateFood";
        }
        const { data: res } = await this.$http.post(
          url,
          params
        );
        if (res.code !== 200) return this.$message.error("添加土特产失败！");
        this.$message.success("添加土特产成功!");
        this.$emit("refreshList");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-checkbox {
  margin: 0 8px 0 0 !important;
}

.previewImg {
  width: 100%;
}

.btnAdd {
  margin-top: 15px;
}
</style>
