import Vue from 'vue'
import App from './App.vue'

import router from './router'
import './plugins/element.js'
// 导入全局样式
import './assets/css/global.css'
// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入表格树
import TreeTable from 'vue-table-with-tree-grid'
// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// 导入富文本编辑器样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// 导入NProgress, 包对应的JS和CSS
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import axios from 'axios'
// 配置请求根路径
// 本机地址

const baseURL = process.env.NODE_ENV == 'development' ? process.env.VUE_APP_BASE_DEVELOPMENT_URL : process.env.VUE_APP_BASE_PRODUCTION_URL;
console.log(process.env, baseURL);

// let baseURL = 'http://127.0.0.1:8888';
// let baseURL = 'https://api.ahsznf.cn';

// axios.defaults.baseURL = 'https://api.ahsznf.cn/api/private/v1/'
// axios.defaults.baseURL = 'http://220.180.8.202:5000/ashxWeb/'
axios.defaults.baseURL = baseURL;
Vue.prototype.$baseURL = baseURL;
// axios.defaults.baseURL = 'http://118.31.115.192:8888/api/private/v1/'
// 远程后台地址 仅供参考
// axios.defaults.baseURL = 'https://www.liulongbin.top:8888/api/private/v1/'
// 在request 拦截器中, 展示进度条 NProgress.start()
// 请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
axios.interceptors.request.use(config => {
  NProgress.start()
  console.log(config)
  if (config.url.includes("https://")) {
    return config;
  }
  // 为请求头对象，添加token验证的Authorization字段
  // config.headers.Authorization = window.sessionStorage.getItem('token')
  // let userId = window.sessionStorage.getItem("userId");
  let token = window.sessionStorage.getItem("token");
  // console.log("userId", userId)
  if (config.method == "get") {
    config.params = config.params || {};
    // config.params.userid = userId;
    config.params.platform = "admin"
  } else {
    config.data = config.data || {};
    // config.data.userid = userId;
    config.data.platform = "admin";
  }
  config.headers = config.headers || {};
  token && (config.headers["myToken"] = token);
  // 在最后必须 return config
  return config
})
// response 拦截器中,  隐藏进度条NProgress.done()
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})
// 挂在到Vue实例，后面可通过this调用
Vue.prototype.$http = axios

import * as utils from './common/util.js'
Vue.prototype.$utils = utils


import { getDicList, getUserId, imageResize } from './common/commonFunc.js'
Vue.prototype.$getDicList = getDicList
Vue.prototype.$getUserId = getUserId
Vue.prototype.$imageResize = imageResize

Vue.prototype.$round = (n) => Math.floor(n) / 100;

Vue.config.productionTip = false
// 组件全局注册 表格树
Vue.component('tree-table', TreeTable)
// 全局注册富文本编辑器
Vue.use(VueQuillEditor)
Vue.filter('dataFormat', function (originVal) {
  if (!originVal) {
    return '';
  }
  // originVal = originVal.replace('T', ' ').replace('Z', '');
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  // yyyy-mm-dd hh:mm:ss
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

import store from "./store";
Vue.prototype.$store = store;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Date.prototype.Format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : ((
      "00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}