<template>
	<div>
		<!-- 面包屑导航区 -->
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>服务管理</el-breadcrumb-item>
			<el-breadcrumb-item>基础服务列表</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- 卡片视图 -->
		<el-card>
			<el-row :gutter="20">
				<el-col :span="6">
					<el-input placeholder="请输入内容" v-model="queryInfo.query" clearable @clear="getList">
						<el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
					</el-input>
				</el-col>
				<el-col :span="4">
					<el-button type="primary" @click="goAddPage">发布服务</el-button>
				</el-col>
			</el-row>
			<!-- 表格数据 -->
			<el-table id="mainTable" :height="tableHeight" :data="list" border stripe>+
				<el-table-column width="50px" label="服务编号" prop="ID"></el-table-column>
				<el-table-column width="125px" label="服务图片">
					<template slot-scope="scope">
						<img :src="scope.row.image" width="100px" height="100px" />
					</template>
				</el-table-column>
				<el-table-column width="150px" label="服务名称" prop="name"></el-table-column>
				<el-table-column width="120px" label="服务类型">
					<template slot-scope="scope">
						<el-tag type="success">{{ scope.row.typeName }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column width="100px" label="服务品种">
					<template slot-scope="scope">
						<el-tag>{{scope.row.varietyName}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column width="150px" label="服务价格(元/亩)">
					<template slot-scope="scope">
						<el-tag type="success">{{ $round(scope.row.price) }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column width="150px" label="服务计量单位" prop="unit"></el-table-column>
				<el-table-column width="300px" label="服务介绍" prop="description"></el-table-column>
				<el-table-column width="200px" label="创建时间" prop="add_time">
					<template slot-scope="scope">{{
						scope.row.createtime | dataFormat
					  }}</template>
				</el-table-column>
				<el-table-column width="100px" label="当前状态">
					<template slot-scope="scope">
						<el-tag style="color:gray" v-if="scope.row.state==0">已下架</el-tag>
						<el-tag style="color:green" v-if="scope.row.state==1">已上架</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="200px" fixed="right">
					<template slot-scope="scope">
						<el-button type="primary" icon="el-icon-edit" size="mini"
							@click="editByID(scope.row)">编辑</el-button>
						<el-button type="danger" icon="el-icon-delete" size="mini"
							@click="removeByID(scope.row.ID)">删除</el-button>
						<el-button v-if="scope.row.state == 1" type="danger" icon="el-icon-delete" size="mini"
							@click="updateStatus(scope.row.ID, 0)">下架</el-button>
						<el-button v-if="scope.row.state == 0" type="danger" icon="el-icon-delete" size="mini"
							@click="updateStatus(scope.row.ID, 1)">上架</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页区域 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryInfo.page" :page-sizes="[10, 15, 20]" :page-size="queryInfo.limit"
				layout="total, sizes, prev, pager, next, jumper" :total="total" background></el-pagination>
		</el-card>

		<el-dialog :title="isEdit?'修改基础服务信息':'添加基础服务信息'" :visible.sync="editDialogVisible" width="50%">
			<AddService :detail="currentDetail" :isEdit="isEdit" @refreshList="refreshList" />
		</el-dialog>
	</div>
</template>

<script>
	import {
		services
	} from "@/config/config.js";
	import AddService from "./Add.vue";
	import { myMixins } from "@/mixins/myMixins.js";
	export default {
		components: {
			AddService
		},
		mixins: [myMixins],
		data() {
			return {
				queryInfo: {
					action: "serviceList",
					query: "",
					page: 1,
					limit: 10,
					type: -1,
					path: "ashxWeb/MaServices.ashx"
				},
				editDialogVisible: false,
				// 服务列表
				list: [],
				// 服务总数
				total: 0,
				isEdit: false,
				currentDetail: null,
				serviceTypeList: [],
				serviceVarietyList: []
			};
		},
		created() {
			this.getList();
			this.getDictionaryList();
		},
		methods: {
			refreshList() {
				console.log("更新列表")
				this.getList();
				this.editDialogVisible = false;
			},
			async getDictionaryList() {
				this.$getDicList(1).then((list) => {
					this.serviceTypeList = list;
				});
				this.$getDicList(2).then((list) => {
					this.serviceVarietyList = list;
				});
				console.log("获取服务")
			},
			// 根据分页获取对应的服务列表
			async getList() {
				let params = _.cloneDeep(this.queryInfo);
      			params.page--;
				const {
					data: res
				} = await this.$http.get(
					"/openapi/web_proxy_get", 
					// "web_api/machine_service/service_type_list", 
					{
					params
				});
				if (res.code !== 200) {
					return this.$message.error("获取服务列表失败！");
				}
				this.list = res.data ? res.data : services;
				this.total = res.total || this.list.length;
			},
			handleSizeChange(newSize) {
				this.queryInfo.limit = newSize;
				this.getList();
			},
			handleCurrentChange(newSize) {
				this.queryInfo.page = newSize;
				this.getList();
			},
			// 通过ID删除服务
			async removeByID(ID) {
				const confirmResult = await this.$confirm(
					"此操作将永久删除该服务, 是否继续?",
					"提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}
				).catch((err) => err);
				if (confirmResult !== "confirm") {
					return this.$message.info("已取消删除！");
				}
				const {
					data: res
				} = await this.$http.post("openapi/proxy_post", {
					action: "ServiceTypeDel",
					path: "ashxWeb/MaServices.ashx",
					id: ID
				});
				this.$message.success("删除服务成功！");
				this.getList();
			},
			goAddPage() {
				this.isEdit = false;
				this.currentDetail = null;
				this.editDialogVisible = true
				// this.$router.push("/services/add");
			},
			updateStatus(ID, flag) {
				this.$confirm(flag == 1 ? '是否确认上架该服务' : '是否确认下架该服务', '温馨提示', {
					confirmButtonText: '确定',
					callback: async (action) => {
						if (action == 'confirm') {
							const {
								data: res
							} = await this.$http.post("openapi/proxy_post", {
								action: "update_state",
								path: "ashxWeb/MaService.ashx",
								state: flag,
								id: ID
							});
							if (res.code !== 200) {
								return this.$message.error("操作失败！");
							}
							this.$message.success("操作成功！");
							this.getList();
						}
					}
				});
			},
			editByID(current) {
				console.log(current);
				this.isEdit = true;
				this.currentDetail = current;
				this.editDialogVisible = true
			}
		},
	};
</script>

<style lang="less" scoped></style>