<template>
<div>
  <el-form :model="updateProgressForm" ref="updateProgressFormRef" label-width="200px">
    <el-form-item label="配送日期" required>
      <el-date-picker v-model="updateProgressForm.deliverDate" align="right" type="date" value-format="yyyy-MM-dd"
        placeholder="选择日期" :picker-options="pickerOptions">
      </el-date-picker>
    </el-form-item>
    <el-form-item label="本次配送数量(件/袋)" required>
      <el-input v-model="updateProgressForm.number" placeholder="在此输入本次配送的数量"></el-input>
    </el-form-item>
    <!-- <el-form-item label="当前剩余配送数量(件)">
          <el-tag
            type="success"
            size="mini"
            style="width: 100px; text-align: center"
            >{{
              currentOrder
                ? currentOrder.number - currentOrder.deliveredNumber
                : 0
            }}</el-tag
          >
        </el-form-item> -->
    <el-form-item label="发货/签收凭证(可传多张)" required>
      <upload name="deliver_proof_image" :initFileList="initFileList" :multiple="isMultiple"
        @handleUploadSuccess="handleUploadSuccess" @handleRemove="handleRemove"></upload>
    </el-form-item>
    <!-- <el-form-item label="货物拍照(选填)">
          <upload
            name="deliver_goods_image"
            :initFileList="initFileList"
            :multiple="isMultiple"
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
        <el-form-item label="签收人拍照(选填)">
          <upload
            name="deliver_signer_image"
            :initFileList="initFileList"
            :multiple="isMultiple"
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item> -->
    <el-form-item label="配送人姓名" required>
      <el-input v-model="updateProgressForm.deliverName" placeholder="在此输入本次配送人的姓名"></el-input>
    </el-form-item>
    <el-form-item label="配送人联系方式">
      <el-input v-model="updateProgressForm.mobile" placeholder="在此输入本次配送人的联系方式"></el-input>
    </el-form-item>
    <el-form-item label="签收人姓名" required>
      <el-input v-model="updateProgressForm.signerName" placeholder="在此输入签收人的姓名"></el-input>
    </el-form-item>
    <el-form-item label="签收人联系方式">
      <el-input v-model="updateProgressForm.signerMobile" placeholder="在此输入签收人的联系方式"></el-input>
    </el-form-item>
    <el-form-item label="备注">
      <el-input v-model="updateProgressForm.remark" placeholder="在此输入备注信息"></el-input>
    </el-form-item>
    <el-button :loading="isApply" style="width: 300px; margin: 0 auto; margin-left: 200px" type="primary"
      @click="updateDeliverProgressConfirm">确 定</el-button>
  </el-form>
</div>
</template>

<script>
import _ from "lodash";
import upload from "@/components/upload";
export default {
  components: {
    upload
  },
  props: {
    orderNo: String,
    detail: Object
  },
  data() {
    return {
      isApply: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      initFileList: [],
      updateProgressForm: {
        number: "",
        image: "",
        deliverName: "",
        mobile: "",
        deliverDate: "",
        signerName: "",
        signerMobile: "",
        remark: ""
      },
      deliverImages: [],
      updateProgressDialogVisible: false,
      isMultiple: true
    };
  },
  computed: {

  },
  watch: {
    detail: {
      handler(nval, oval) {
        if (nval) {
          let _newObj = _.cloneDeep(nval);
          for (let key in this.updateProgressForm) {
            this.updateProgressForm[key] = _newObj[key];
          }
          if (this.updateProgressForm.image) {
            this.initFileList = [{
              url: this.updateProgressForm.image
            }];
          } else {
            this.initFileList = [];
          }
        } else {

        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    let that = this;

  },
  mounted() {

  },
  methods: {
    handleRemove(response) {
      if (response && response.name == "deliver_proof_image") {
        console.log(response);
        this.deliverImages = response.value.map((item) => {
          return item.url;
        });
      }
    },
    // 监听图片上传成功事件
    handleUploadSuccess(response) {
      console.log("--------------");
      console.log(response);
      if (response && response.name == "deliver_proof_image") {
        this.deliverImages = response.value.map((item) => {
          return item.url;
        });
      }
    },
    async updateDeliverProgressConfirm() {
      try {
        console.log(this.updateProgressForm);
        // return;
        if (!this.deliverImages.length) {
          return this.$message.error("请上传凭证！");
        }
        if (this.updateProgressForm.number <= 0) {
          return this.$message.error("请输入正确的配送数量");
        }
        if (!this.updateProgressForm.deliverDate) {
          return this.$message.error("请选择配送日期");
        }

        if (!this.updateProgressForm.deliverName) {
          return this.$message.error("配送人姓名不能为空");
        }

        if (!this.updateProgressForm.signerName) {
          return this.$message.error("签收人姓名不能为空");
        }

        // if (
        //   this.updateProgressForm.number >
        //   this.currentOrder.number - this.currentOrder.deliveredNumber
        // ) {
        //   return this.$message.error("超出剩余应配送数量");
        // }

        // let userId = this.$getUserId();
        let params = _.cloneDeep(this.updateProgressForm);
        // params.path = "ashxWeb/web_goods.ashx";
        // params.action = "shop_orderDeliverList_Add";
        // params.userid = userId;
        params.orderNo = this.orderNo;
        params.image = this.deliverImages.join(",");
        this.isApply = true;
        const { data: res } = await this.$http.post("/web_api/supplier/update_deliver", params);
        this.isApply = false;
        if (res.code !== 200) {
          return this.$message.error(res.msg || "操作失败！");
        }
        this.$message.success("操作成功！");
        this.$emit("refreshList")
      } catch (err) {
        this.isApply = false;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
</style>
